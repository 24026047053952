body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Slab', serif; }

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

strong {
  font-weight: 700; }

.align-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.hide {
  display: none; }

/* Hack jlm theme */
#menu-menu-categories a {
  font-weight: 700; }

label {
  font-weight: 400; }

@media (max-width: 990px) {
  .navbar-supporter .collapse {
    border: 0;
    display: block; }
  .navbar-supporter .collapse .navbar-nav {
    margin: 0; } }

@media (max-width: 767px) {
  .navbar-supporter a {
    font-size: 14px; } }

@media (max-width: 1199px) {
  header.banner .container {
    width: 95%; }
  .navbar-default .navbar-nav > li > a {
    font-size: 13px; }
  .navbar-default .navbar-brand img {
    padding: 10px 0; } }
